<template>
  <form style="margin-top:15px" novalidate class="md-layout md-alignment-center-center"
        @submit.prevent="validateForgot">

    <md-card class="md-layout-item md-size-40 md-small-size-70 forgot-layout">

      <md-card-header>
        <div class="md-title">{{ $t('forgotPassword.title') }}</div>
        <div>
          <img class="forgot-logo" :src="`${publicPath}Wibki-logo.png`" width="50%">
        </div>
      </md-card-header>

      <md-card-content>
        <md-field :class="[getValidationClass('username'),'md-field-auth']">
          <md-input name="username"
                    id="username"
                    type="username"
                    autocomplete="Username"
                    v-model="form.username"
                    :disabled="isLoading"
                    @focus="clearErrors('username')" class="md-input-auth input-with-border" placeholder="Username or email"/>

          <div class="md-error" v-if="!$v.form.username.required">
            {{ $t('missingValue') }}
          </div>
          <div class="md-error" v-else-if="!$v.form.username.minlength">
            {{ $t('usernameMinimumLength') }}
          </div>
          <div class="md-error" v-else-if="!$v.form.username.username">
            {{ $t('validUsername') }}
          </div>
        </md-field>

        <div class="error-message" v-for="error in errors.username" :key="error">{{error}}</div>
      </md-card-content>

      <md-progress-bar :class="md-accent" md-mode="indeterminate" v-if="isLoading" />

      <md-card-actions class="forgot-btn-container">
        <md-button type="submit" class="md-raised md-accent forgot-auth-btn" :disabled="isLoading">
          {{ $t('forgotPassword.forgotBtn') }}
        </md-button>
      </md-card-actions>
    </md-card>

  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength,
} from 'vuelidate/lib/validators';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import { FORGOT_PASSWORD } from '@/store/modules/auth/authActions.type';

export default {
  name: 'UserForgotPassword',
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      username: null,
    },
    publicPath: process.env.BASE_URL,
  }),
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.form.username = null;
    },
    validateForgot() {
      this.$v.$touch();
      this.errors = {};

      if (!this.$v.$invalid) {
        this.loginAction();
      }
    },
    loginAction() {
      const payload = {
        username: this.form.username,
      };
      this.$store.dispatch(FORGOT_PASSWORD, payload).then((response) => {
        this.$log.info(FORGOT_PASSWORD, payload);
        this.$toasted.show(response.message, {
          type: 'success',
          position: 'top-right',
          duration: 5000,
          icon: 'check',
        });
        this.clearForm();

        this.$router.push('/');
      }).catch((error) => {
        this.$log.error(FORGOT_PASSWORD, error);
        this.$toasted.show(error.message, {
          type: 'error',
          position: 'top-right',
          duration: 5000,
          icon: 'alert',
        });
        if (error.status === 'validation_error' && error.errors) {
          this.errors = error.errors;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common/auth/auth-panel.scss';
</style>
