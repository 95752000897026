<template>
  <md-card>
    <div id="fave-container" class="md-card md-layout-item md-size-100 fave-card">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <div class="md-layout md-size-60 md-alignment-center header-step">
            <p class="md-headline">Getting Started</p>
            <p class="sub-headline">Fav your sites easier</p>
          </div>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-100 chrome-extension-container">
          <div class="md-layout md-alignment-center-center">
            <a class="md-button md-raised download-button md-theme-default chrome-extension"
              onclick="alert('Drag me to the bookmarks bar'); return false;"
              href="javascript:(function(){
                     window.open('https://wibki.com/user/bookmarklet?link='
                     +encodeURIComponent(window.location.href)+'&amp;title='+encodeURIComponent(document.title),'_blank','width=800, height=600')}())" title="Fave It"
              id="FaveItButton">
              <div class="fa-container">
                <i class="fas fa-plus"></i>
              </div>
              <div class="chrome-text-container">
                <strong class="chrome-text">Add it to your Bookmarks</strong><span></span>
              </div>
            </a>
          </div>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-100">
          <div class="md-layout md-alignment-center-center md-size-100">
            <p class="bookmark-button-instructions"> <b class="bold-title-instruction">{{
              $t('bookmarklet.installBookmarkBtn')
            }}</b>
            <ol>
              <li>{{ $t('bookmarklet.installex1') }}</li>
              <li>{{ $t('bookmarklet.installex4') }}</li>
              <li>{{ $t('bookmarklet.installex5') }}</li>
            </ol>
            </p>
          </div>
        </div>

        <div class="seperator"></div>

        <div class="md-layout-item md-small-size-100 md-size-100">
          <div class="md-layout md-alignment-center-center">
            <p class="md-headline">Chrome Extension</p>
          </div>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-100">
          <div class="md-layout md-alignment-center-center md-size-100">
            <p class="bookmark-button-instructions">
              <b class="bold-title-instruction">
                To install the Chrome Extension:
              </b>
            <ol>
              <li>Click the <strong>blue button</strong> under and easily download the Chrome Extension</li>
              <li>Now, when you're browsing the web, simply click the WIBKI logo, in your extensions erea, to bookmark a
                website to your account</li>
              <li>Easily add a new folder of interest to your account. In addition, the extension allows you to
                import all your Chrome bookmarks. <br> In a simple one click, directly to your account. </li>
            </ol>
            <ol style="display: flex; align-items: center; justify-content: center;">
              <a href="https://chrome.google.com/webstore/detail/wibki-your-key-to-the-web/begppnajhhlcipeppengikkbehnjhgfj?hl=en-US&amp;hc=search&amp;hcp=main"
                onclick="_gaq.push(['_trackEvent', 'bookmark','chromebutton', 'click']);" target="_blank">
                <img src="//img1.minifav.net/images/signup-new/add-to-chrome .png" alt="Fave It" title="Fave It"
                  style="cursor: pointer; border-style: none;">
              </a>
            </ol>
            </p>
          </div>
        </div>
      </div>
    </div>
    <md-card-actions>
      <md-button type="submit" class="md-primary md-raised next-step-login-process" @click="setTour">
        Finish
      </md-button>
    </md-card-actions>
  </md-card>
</template>

<script>

import { SET_TOUR } from '@/store/modules/layout/layoutActions.type';
import {
  GET_AUTH_USER_TABS,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'AddChromeExtensionStep',
  data: () => ({
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
  },
  methods: {
    setTour() {
      this.$store.dispatch(SET_TOUR, true);
      this.$router.push('/');
      this.$store.dispatch(GET_AUTH_USER_TABS).then().catch();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout/login-steps.scss';
@import '@/assets/scss/layout/favebutton.scss';
</style>
